<script lang="ts" setup>
import { ref, onMounted, onUnmounted, nextTick } from "vue"
import { useRouter } from "vue-router"

import {
  setCurrentViewContext,
  setUserLoggedInStatus,
  isUserLoggedIn,
  showFlashMessage,
} from "@/utils/GlobalState"
import { login } from "@/services/Api"
import { getLoginSchema } from "@/schemas/Account"
import { gettext } from "@/utils/Translation"
import LogoLarge from "@/components/svg/LogoLarge.vue"
import BySpoon from "@/components/svg/BySpoon.vue"

const { $gettext } = gettext
const router = useRouter()
const shouldShowForm = ref(false)
const formContainerElement = ref(null)
setCurrentViewContext()

const userIsLoggedIn = isUserLoggedIn()

async function submitForm(data) {
  // We lazily load this because the Account services incurs a cavalcade of
  // imports that bloat the chunk that the Login vue is a part of: the very
  // initial one.
  const { updateCachedAccountInfo, hasOutstandingAgreements, shouldShowIntro } = await import("@/services/Account")
  const { username, password } = data
  this.submitAttrs.inputClass = "button is-loading"
  this.submitLabel = $gettext("Loading")
  login(username, password)
    .then(async () => {
      await updateCachedAccountInfo()
      let nextUp = "SiteList"
      if (hasOutstandingAgreements()) {
        nextUp = "AgreementDocumentList"
      }
      else if (shouldShowIntro()) {
        nextUp = "IntroSlide1"
      }
      router.push({ name: nextUp })
      setUserLoggedInStatus(true)
    })
    .catch((error) => {
      showFlashMessage({ msg: error.userMessage, class: "is-danger" })
      setUserLoggedInStatus(false)
      this.submitAttrs.inputClass = "button"
      this.submitLabel = $gettext("Log In")
    })
}

async function showForm() {
  shouldShowForm.value = true
  await nextTick() // Allow our wrapper to manifest.
  const contentElement = formContainerElement.value.querySelector(".box")
  const expandedHeight = contentElement.getBoundingClientRect().height
  formContainerElement.value.animate(
    [
      { height: "0px", opacity: 0 },
      { height: `${expandedHeight}px`, opacity: 1 },
    ],
    {
      easing: "ease-in-out",
      duration: 1500,
      iterations: 1,
      fill: "forwards",
    },
  )
}

// Hack on a class to the body element, so we can customize appearance of #app
onMounted(() => document.body.classList.add("full-bleed") )
onUnmounted(() => document.body.classList.remove("full-bleed") )

if (!userIsLoggedIn.value) {
  setTimeout(showForm, 1000)
}
</script>

<template>
  <div
    v-if="!userIsLoggedIn"
    :class="{ 'splash-page-wrapper': true, 'has-form': shouldShowForm }"
  >
    <div class="logo branding">
      <LogoLarge />
    </div>

    <div
      v-if="shouldShowForm"
      ref="formContainerElement"
      class="box-wrapper"
      style="height: 0; overflow: hidden; opacity: 0"
    >
      <div class="box">
        <h1 class="title">
          {{ $gettext("Log In") }}
        </h1>
        <FormKit
          type="form"
          :submit-label="$gettext('Log In')"
          :submit-attrs="{ inputClass: 'button is-primary' }"
          @submit="submitForm"
        >
          <FormKitSchema :schema="getLoginSchema()" />
        </FormKit>
      </div>
    </div>

    <div class="by-spoon branding">
      <BySpoon />
    </div>
  </div>
  <div v-else>
    <h1 class="title">
      {{ $gettext("Log In") }}
    </h1>
    <p>
      {{
        $gettext(
          "You are already logged in. To log in with a different account, please log out first.",
        )
      }}
    </p>
    <router-link
      class="button"
      :to="{ name: 'Logout' }"
    >
      {{ $gettext("Log out") }}
    </router-link>
  </div>
</template>

<style scoped lang="scss">
.splash-page-wrapper {
  background-color: #154262; /* $spoon-dark-blue */
  height: 100%;
  width: 100%;
  padding: 1em;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.has-form {
    justify-content: space-evenly;
  }
}
.branding {
  padding: 1em 0;
}
.logo {
  width: 100%;
}
.by-spoon {
  width: 50%;
}
.has-form {
  .logo {
    width: 50%;
    padding: 0;
  }
  .by-spoon {
    width: 33%;
  }
  .branding {
    padding: 0;
  }
}
.box-wrapper {
  width: 100%;
}
</style>
