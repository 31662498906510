import { gettext } from "@/utils/Translation"
import { convertDisplayList } from "@/utils/Utilities"

const { $gettext } = gettext

export function getYesNoOptions() {
  return [
    { value: true, label: $gettext("Yes") },
    { value: false, label: $gettext("No") },
  ]
}

export function getYesNoUnknownOptions() {
  return [
    { value: "yes", label: $gettext("Yes") },
    { value: "no", label: $gettext("No") },
    { value: "unknown", label: $gettext("Unknown") },
  ]
}

// Child-related options
export function getSexOptions() {
  return [
    { value: "female", label: $gettext("Female") },
    { value: "male", label: $gettext("Male") },
    { value: "unknown", label: $gettext("Unknown") },
  ]
}

export function getDiagnosisOptions() {
  return [
    { value: "cerebral palsy", label: $gettext("Cerebral Palsy") },
    { value: "hydrocephalus", label: $gettext("Hydrocephalus") },
    { value: "down syndrome", label: $gettext("Down Syndrome") },
    { value: "hiv/aids", label: $gettext("HIV/AIDS") },
    { value: "cleft lip/palate", label: $gettext("Cleft Lip/Palate") },
    { value: "autism spectrum disorder", label: $gettext("Autism Spectrum Disorder") },
    { value: "heart disease/defect", label: $gettext("Heart Disease/Defect") },
    { value: "cognitive impairment", label: $gettext("Cognitive Impairment") },
    { value: "vision impairment", label: $gettext("Visual Impairment") },
    { value: "premature birth", label: $gettext("Premature birth") },
    { value: "low birth weight (< 2.5 kg)", label: $gettext("Low Birth Weight (<2.5 kg)") },
    { value: "seizure disorder/epilepsy", label: $gettext("Seizure Disorder/Epilepsy") },
    { value: "other", label: $gettext("Other") },
  ]
}

// Child version; don't confuse with site's' similar "getTypesOfServicesOptions"
export function getTypeOfServiceOptions() {
  return [
    { value: "periodical", label: $gettext("Child comes to this site periodically for services") },
    { value: "day", label: $gettext("Child usually spends most of the day at this site") },
    { value: "residential", label: $gettext("Child mostly lives at this site") },
  ]
}

export function getTypeOfCareResidentialOptions() {
  return [
    { value: "group home", label: $gettext("Child lives in a small group home") },
    { value: "institution", label: $gettext("Child lives in a child care facility") },
  ]
}

export function getTypeOfCareCommunityOptions() {
  return [
    { value: "foster care", label: $gettext("Child lives with foster family") },
    { value: "daycare", label: $gettext("Child attends a daycare") },
    { value: "group home", label: $gettext("Child lives in a small group home") },
    { value: "home", label: $gettext("Child lives with parents or relatives") },
  ]
}

export function getReasonForDischargeOptionsResidential() {
  return [
    { value: "", label: "-" },
    { value: "domestic adoption", label: $gettext("Domestic adoption") },
    { value: "international adoption", label: $gettext("International adoption") },
    { value: "transfer to different institution", label: $gettext("Transfer to different institution") },
    { value: "transfer to foster care", label: $gettext("Transfer to foster care") },
    { value: "reunification", label: $gettext("Reunification with biological family") },
    { value: "aged out", label: $gettext("Aged out") },
    { value: "deceased", label: $gettext("Deceased") },
    { value: "other", label: $gettext("Other") },
  ]
}

export function getReasonForDischargeOptionsCommunity() {
  return [
    { value: "", label: "-" },
    { value: "no longer receives services here", label: $gettext("No longer receives services here") },
    { value: "transfer to different institution", label: $gettext("Transfer to different institution") },
    { value: "aged out", label: $gettext("Aged out") },
    { value: "deceased", label: $gettext("Deceased") },
    { value: "other", label: $gettext("Other") },
  ]
}

export function getCauseOfDeathOptions() {
  return [
    { value: "", label: "-" },
    { value: "pneumonia", label: $gettext("Pneumonia") },
    { value: "complications from surgery", label: $gettext("Complications from surgery") },
    { value: "accident", label: $gettext("Accident") },
    { value: "other", label: $gettext("Other") },
  ]
}

// Site-related options
export function getSiteRolesOptions() {
  return [
    {
      value: "site user",
      label: $gettext("Standard User"),
      addLabel: $gettext('Add with role "Standard User"'),
      changeLabel: $gettext('Change role to "Standard User"'),
    },
    {
      value: "site administrator",
      label: $gettext("Site Administrator"),
      addLabel: $gettext('Add with role "Site Administrator"'),
      changeLabel: $gettext('Change role to "Site Administrator"'),
    },
    {
      value: "site supporter",
      label: $gettext("Site Supporter"),
      addLabel: $gettext('Add with role "Site Supporter"'),
      changeLabel: $gettext('Change role to "Site Supporter"'),
    },
    {
      value: "site observer",
      label: $gettext("Site Observer"),
      addLabel: $gettext('Add with role "Site Observer"'),
      changeLabel: $gettext('Change role to "Site Observer"'),
    },
  ]
}

export function getTypesOfServicesOptions() {
  return [
    { value: "periodical", label: $gettext("Children come to this site periodically for services") },
    { value: "day", label: $gettext("Children spend most of the day at this site, but sleep elsewhere") },
    { value: "residential", label: $gettext("Children live at this site") },
  ]
}

export function getTypesOfServicesDisplay(val: string) {
  const map = convertDisplayList(getTypesOfServicesOptions())
  return map[val] || ""
}

export function getProjectRolesOptions() {
  return [
    {
      value: "project administrator",
      label: $gettext("Project Administrator"),
      addLabel: $gettext('Add with role "Project Administrator"'),
      changeLabel: $gettext('Change to "Project Administrator"'),
    },
    {
      value: "project supporter",
      label: $gettext("Project Supporter"),
      addLabel: $gettext('Add with role "Project Supporter"'),
      changeLabel: $gettext('Change to "Project Supporter"'),
    },
    {
      value: "project observer",
      label: $gettext("Project Observer"),
      addLabel: $gettext('Add with role "Project Observer"'),
      changeLabel: $gettext('Change to "Project Observer"'),
    },
  ]
}

// Feeding options
export function getFeedingToolOptions() {
  return [
    { value: "breast", label: $gettext("Breast") },
    { value: "bottle", label: $gettext("Bottle") },
    { value: "cup", label: $gettext("Cup") },
    { value: "spoon", label: $gettext("Spoon") },
    { value: "hands", label: $gettext("Hands") },
  ]
}
export function getFeedingTextureOptions() {
  return [
    { value: "breast milk", label: $gettext("Breast milk") },
    { value: "formula", label: $gettext("Formula") },
    { value: "other liquids", label: $gettext("Other liquids") },
    { value: "solids", label: $gettext("Solid foods of any texture") },
  ]
}
// These options are arbitrarily culled from a list generated
// by django countries. To recreate, do this:
//    from django_countries import countries
//    for code, name in countries:
//      print(f'  {{ value: "{code}", label: $gettext("{name}") }},')
export function getCountryOptions() {
  return [
    { value: "AO", label: $gettext("Angola") },
    { value: "AM", label: $gettext("Armenia") },
    { value: "BY", label: $gettext("Belarus") },
    { value: "BJ", label: $gettext("Benin") },
    { value: "BO", label: $gettext("Bolivia") },
    { value: "BW", label: $gettext("Botswana") },
    { value: "BR", label: $gettext("Brazil") },
    { value: "KH", label: $gettext("Cambodia") },
    { value: "CM", label: $gettext("Cameroon") },
    { value: "CN", label: $gettext("China") },
    { value: "CO", label: $gettext("Colombia") },
    { value: "CG", label: $gettext("Congo") },
    { value: "CD", label: $gettext("Congo (the Democratic Republic of the)") },
    { value: "CI", label: $gettext("Côte d'Ivoire") },
    { value: "HR", label: $gettext("Croatia") },
    { value: "SZ", label: $gettext("Eswatini") },
    { value: "ET", label: $gettext("Ethiopia") },
    { value: "GA", label: $gettext("Gabon") },
    { value: "GM", label: $gettext("Gambia") },
    { value: "GH", label: $gettext("Ghana") },
    { value: "GT", label: $gettext("Guatemala") },
    { value: "GN", label: $gettext("Guinea") },
    { value: "GW", label: $gettext("Guinea-Bissau") },
    { value: "HT", label: $gettext("Haiti") },
    { value: "HN", label: $gettext("Honduras") },
    { value: "IN", label: $gettext("India") },
    { value: "KZ", label: $gettext("Kazakhstan") },
    { value: "KE", label: $gettext("Kenya") },
    { value: "KW", label: $gettext("Kuwait") },
    { value: "LA", label: $gettext("Laos") },
    { value: "LB", label: $gettext("Lebanon") },
    { value: "LS", label: $gettext("Lesotho") },
    { value: "LR", label: $gettext("Liberia") },
    { value: "LY", label: $gettext("Libya") },
    { value: "MG", label: $gettext("Madagascar") },
    { value: "MW", label: $gettext("Malawi") },
    { value: "MY", label: $gettext("Malaysia") },
    { value: "MR", label: $gettext("Mauritania") },
    { value: "MU", label: $gettext("Mauritius") },
    { value: "MX", label: $gettext("Mexico") },
    { value: "MN", label: $gettext("Mongolia") },
    { value: "MA", label: $gettext("Morocco") },
    { value: "MZ", label: $gettext("Mozambique") },
    { value: "MM", label: $gettext("Myanmar") },
    { value: "NA", label: $gettext("Namibia") },
    { value: "NI", label: $gettext("Nicaragua") },
    { value: "NE", label: $gettext("Niger") },
    { value: "NG", label: $gettext("Nigeria") },
    { value: "PK", label: $gettext("Pakistan") },
    { value: "PE", label: $gettext("Peru") },
    { value: "RW", label: $gettext("Rwanda") },
    { value: "SN", label: $gettext("Senegal") },
    { value: "SL", label: $gettext("Sierra Leone") },
    { value: "ZA", label: $gettext("South Africa") },
    { value: "SS", label: $gettext("South Sudan") },
    { value: "SD", label: $gettext("Sudan") },
    { value: "TJ", label: $gettext("Tajikistan") },
    { value: "TZ", label: $gettext("Tanzania") },
    { value: "TH", label: $gettext("Thailand") },
    { value: "UG", label: $gettext("Uganda") },
    { value: "VE", label: $gettext("Venezuela") },
    { value: "VN", label: $gettext("Vietnam") },
    { value: "ZM", label: $gettext("Zambia") },
    { value: "ZW", label: $gettext("Zimbabwe") },
  ]
}

export function getCountryDisplay(val: string) {
  const map = convertDisplayList(getCountryOptions())
  return map[val] || ""
}
