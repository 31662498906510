import { createGlobalState, useStorage } from "@vueuse/core"
import dayjs from "dayjs"
import { defineStore } from "pinia"
import { ref } from "vue"

import { CONFIG } from "@/config"
import { db } from "@/db"
import { gettext } from "@/utils/Translation"
import { isEmpty } from "@/utils/Utilities"

const { $gettext } = gettext

// Transient state is to pass ephemeral data from view to view on the same page
const useTransientGlobalState = createGlobalState(() => {
  return {
    flashMessages: ref([]),
  }
})

// Cache a flag for whether we *believe* the user is still logged in.
const useUserIsLoggedInStore = createGlobalState(
  // Boolean
  () => useStorage("userIsLoggedIn", false),
)

// Cache information about the current user, inc name, username, some permissions
const useAccountInfoStore = createGlobalState(
  // Object/null
  () => useStorage("accountInfo", {}),
)

// Cache the language code implicitly or explicitly preferred by the user.
export const useLanguagePreferenceStore = createGlobalState(() =>
  useStorage("languagePreference", null),
)

// Map of context keys (e.g. siteChildren-123) to the timestamp when last notified
// about potential staleness for the data in that context.
const useStalenessNotificationsStore = createGlobalState(
  () => useStorage("stalenessNotifications", {}),
)

/**
 * Adds a flash message to the flash message queue. See
 * FlashMessage.vue for view logic.
 * @param {Object} options
 * @param {string} options.msg - The message to be displayed.
 * @param {string} [options.class] - The css class for the notification.
      Default: "is-info"
 * @param {number} [options.duration] - Duration (in ms) it should be shown.
      0 means show indefinitely. Default: 5 seconds.
 * @param {number} [options.appearanceDelay] - Time of brief delay (in ms)
      before animating msg appearance. 0 means show immediately. Default: 400 ms.
 */
export function showFlashMessage(options: { msg: string, class?: string, duration?: number, appearanceDelay?: number }) {
  const { flashMessages } = useTransientGlobalState()
  options.class = options.class ?? "is-info"
  options.duration = options.duration ?? 5000
  options.appearanceDelay = options.appearanceDelay ?? 400
  flashMessages.value.push(options)
}

/**
 * Return the value of current flash messages.
 */
export function getFlashMessages() {
  const { flashMessages } = useTransientGlobalState()
  return flashMessages.value
}

/**
 * Returns bool based on internally stored flag recording that user *did*
 * log in and did not log out. However, this could be a false positive if
 * the session expired.
 */
export function isUserLoggedIn() {
  return useUserIsLoggedInStore()
}

/**
 * Sets locally cached account info (for current user) from the backend.
 */
export function setAccountInfo(accountInfo?: object) {
  const accountInfoStore = useAccountInfoStore()
  accountInfoStore.value = !isEmpty(accountInfo) ? accountInfo : null
}

/**
 * Gets locally cached account info for current user.
 */
export function getAccountInfo() {
  const accountInfoStore = useAccountInfoStore()
  return accountInfoStore.value
}


/**
 * Returns the one-letter race code representing the inferred race to use for
 * images.
 *  "a": olive-skinned (Latin America, Middle East, India, etc)
 *  "b": East Asian
 *  "c": darker skinned (Africa)
 *  "d": very light skinned (many post-Soviet countries)
 *  (The "e" series is a black & white version that's more customized than
 *  just desaturating the others.)
*/
export function getRaceCode() {
  const accountInfo = getAccountInfo()
  return accountInfo["raceCode"] || "c"
}

/**
 * Sets internally stored flag recording that user is/isn't logged in
 * as far as we know.
 * @param {bool} newStatus - true if logged in; false if not logged in.
 */
export function setUserLoggedInStatus(newStatus: boolean) {
  const userIsLoggedIn = useUserIsLoggedInStore()
  userIsLoggedIn.value = newStatus
}


/*
* Utilities related to last update times. We store last update times in the database
* for a variety of things. We consider them fresh unless they're more than a day old.
* We also reveal those details to the user.
*/

/**
 * Returns bool reflecting whether site data in IndexedDB is thought to be stale
 * based on a threshold time.
 *
 * @return [bool]
 */
export async function areCachedDataStale(args: { type: string, localItemId?: number }) {
  const lastUpdatesFromServer = await db.lastUpdated.get(args)
  if (!lastUpdatesFromServer) {
    return true
  }
  // Threshold for staleness: 1 day
  return dayjs(lastUpdatesFromServer.date)
    .isBefore(dayjs().subtract(1, "day"))
}

/*
 * Store to IndexedDB the current timestamp, which represents when we last polled
 * the server for whatever type of info is in args.
*/
export async function updateLastUpdateTime(args: { type: string, localItemId?: number }) {
  const data = {
    id: null,
    date: new Date(),
  }
  const existing = await db.lastUpdated.get(args)
  if (existing) {
    data.id = existing.id
    await db.lastUpdated.put({ ...data, ...args })
  }
  else {
    delete data.id
    await db.lastUpdated.add({ ...data, ...args })
  }
}

export async function getLastUpdateInfo(args: { type: string, localItemId?: number }) {
  const fromDb = await db.lastUpdated.get(args)
  return fromDb ? fromDb.date.toLocaleString() : null
}

// Set current time for the context represented by @param key. That is,
// the user was *just* notified about potential staleness for this context.
// If a falsy arg/no arg is passed, it nukes all data (as in when user logs out.)
export function setStalenessNotificationTime(key?: string) {
  const stalenessNotificationStore = useStalenessNotificationsStore()
  if (key) {
    stalenessNotificationStore.value[key] = new Date()
  }
  else {
    stalenessNotificationStore.value = {}
  }
}

// Return boolean indicating if the user has been notified–within the past 24 hours–about
// stale content for the context represented by @param key.
export function hasBeenNotifiedAboutStaleness(key: string) {
  const stalenessNotificationStore = useStalenessNotificationsStore()
  if (!stalenessNotificationStore.value[key]) {
    return false
  }
  return dayjs().diff(stalenessNotificationStore.value[key], "hour") < 24
}

export const useUploadStatusStore = defineStore("UploadStatusStore", () => {
  const pendingUploadCount = ref(null)
  const isCurrentlySyncing = ref(false)
  function endSync() {
    isCurrentlySyncing.value = false
    // Async but that's ok
    updatePendingUploadCount()
  }

  function startSync() {
    isCurrentlySyncing.value = true
  }

  async function updatePendingUploadCount() {
    pendingUploadCount.value = await db.pendingUploads.count()
  }
  return { pendingUploadCount, isCurrentlySyncing, endSync, startSync, updatePendingUploadCount }
})

export async function updatePendingUploadCount() {
  const { updatePendingUploadCount } = useUploadStatusStore()
  updatePendingUploadCount()
}


export const useCurrentViewContextStore = defineStore("CurrentViewContextStore", {
  state: () => {
    return {
      context: null,
      type: "",
    }
  },
  actions: {
    updateContext(context: object, type: string) {
      this.context = context
      this.type = type
    },
    getNavBarTitle() {
      switch (this.type) {
        case "site":
        case "project":
          return this.context?.name
        case "child":
          return this.context?.fullName
        case "library":
          return $gettext("Resource Library")
        case "documentation":
          return $gettext("Documentation")
        default:
          return "Count Me In"
      }
    },
    getSiteId() {
      switch (this.type) {
        case "site":
          return this.context?.cmiId
        case "child":
          return this.context?.siteId
        default:
          return 0
      }
    },
    async getTrainingMode() {
      const { getSiteByCmiId } = await import("@/services/Site")

      switch (this.type) {
        case "site":
          return this.context?.inTrainingMode
        case "child": {
          const site = await getSiteByCmiId(this.context.siteId)
          return site.inTrainingMode
        }
        default:
          return false
      }
    },
  }
})

export function setCurrentViewContext(context?: object, type?: string) {
  const currentViewContextStore = useCurrentViewContextStore()
  if (context || type) {
    currentViewContextStore.updateContext(context, type)
  }
  else {
    currentViewContextStore.$reset()
  }
}

// Add a data field to the <body> so we can tell what version (Git SHA) is on a given
// installation of the app. (This is also tracked separately in Sentry.)
export function appendSha() {
  const bodyEl = document.querySelector("body");
  bodyEl.setAttribute("data-release", CONFIG.COMMIT_SHA)
}
